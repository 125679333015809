import React, { useRef, Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styles from "../css/dark-theme.module.css";
import {Send} from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from "axios";
import isEmail from 'validator/lib/isEmail';

const EmailForm = ({children}) => {
    const emailInputValue = useRef('');
    const authKey = process.env.REACT_APP_AUTH_KEY;
    const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
    const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
    const nodeEnv = process.env.REACT_APP_NODE_ENV;
    let serverUrl = 'Check env';
    if (nodeEnv === 'dev') {
        serverUrl = localServerUrl;
    }
    if (nodeEnv === 'prod') {
        serverUrl = prodServerUrl;
    }

    function handleSubmit(){
        if (isEmail(emailInputValue.current.value)) {
            const packets = {
                authToken: authKey,
                email: emailInputValue.current.value

            };
            axios.post(serverUrl+'/apiv2/s/saveemail', packets)
                .then(
                    response => alert(JSON.stringify(response.data.data))
                )
                .catch(error => {
                    console.log("ERROR:: ", error.response.data);

                });
        } else {
            alert("You are input something wrong. Please input your email.");
        }
    }

    return (
        <React.Fragment>
            <Box xs={{flexGrow: 1}}>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}  lg={10} className={styles.email_form}>
                        <Grid container spacing={1} p={3} direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} lg={12} className={styles.email_form_block}>
                                <h2>Do you want to receive daily or weekly reports?</h2>
                            </Grid>
                            <Grid item xs={12} lg={5} className={styles.email_form_block}>
                            </Grid>
                            <Grid item xs={12} lg={2} className={styles.email_form_block}>
                                <small>Input your email on field:</small>
                            </Grid>
                            <Grid item xs={12} lg={5} className={styles.email_form_block}>
                            </Grid>
                            <Grid item xs={12} lg={3}> </Grid>
                            <Grid item xs={12} lg={8}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': {m: 1,}, alignItems: 'center', justifyContent: 'center'
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField id="outlined-basic" label="Email" variant="outlined"
                                               InputLabelProps={{
                                                   style: {color: "white"},
                                               }}
                                               sx={{
                                                   ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                                                       color: "white",
                                                   }, width: "60%", color: "white"
                                               }}
                                               InputProps={{
                                                   sx: {
                                                       ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                                           border: "2px solid white",
                                                       },
                                                       "&:hover": {
                                                           ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                                               border: "2px solid white",
                                                           },
                                                       }, color: "white"
                                                   },
                                               }}
                                               inputRef={emailInputValue}
                                    />
                                    <Button variant="contained" size="large" endIcon={<Send/>} onClick={handleSubmit}
                                            className={styles.email_form_button}>Send</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={1}> </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default EmailForm;