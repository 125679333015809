import React, { useEffect, useRef, memo } from 'react';

function ScreenerScriptTradingView() {
    const container = useRef();

    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
  "width": "100%",
  "height": "500",
  "defaultColumn": "overview",
  "defaultScreen": "general",
  "market": "crypto",
  "showToolbar": true,
  "colorTheme": "dark",
  "locale": "en"
        }`;
            container.current.appendChild(script);
        },
        []
    );

    return (
        <div className="tradingview-widget-container" ref={container}>
        </div>
    );
}

export default memo(ScreenerScriptTradingView);