import React from 'react';

//components
import EmailForm from '../components/EmailForm';

const LayoutEmailForm = ({ children }) => {
    const pageLink = window.location.pathname;
    const unsubscribeLink1 = '/unsubscribe';
    const unsubscribeLink2 = '/unsubscribe/';

    if (pageLink === unsubscribeLink1 || pageLink === unsubscribeLink2) {

    } else {
        return (
            <React.Fragment>
                <EmailForm/>
                {children}
            </React.Fragment>
        );
    }
};

export default LayoutEmailForm;