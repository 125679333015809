import Lottie from 'react-lottie-player';
import lottieJson from './lottie/111844-schedule.json';
import loadingLottieJson from './lottie/animation_lkfxk9f1.json';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import styles from "./css/dark-theme.module.css";
import React, {Component} from "react";
import axios from "axios";
import Countdown from 'react-countdown';
import { ElectricBolt, CheckCircle, Send } from '@mui/icons-material';

const authKey = process.env.REACT_APP_AUTH_KEY;
const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
const nodeEnv = process.env.REACT_APP_NODE_ENV;
let serverUrl = 'Check env';
if (nodeEnv === 'dev') {
    serverUrl = localServerUrl;
}
if (nodeEnv === 'prod') {
    serverUrl = prodServerUrl;
}

class Events extends Component {

    state = {
        isLoaded: false,
        apiData: [],
    };
    async componentDidMount() {
        const packets = {
            authToken: authKey,
        };
        try {
        await axios.post(serverUrl + '/apiv2/g/getevents', packets, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            this.setState({ apiData: response.data.data, isLoaded: true,});
        });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const lottieWidth = window.innerWidth * 30 / 100;

        if (!this.state.isLoaded) {
            return (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4}> </Grid>
                    <Grid item xs={12} lg={4}>
                <Lottie
                    loop
                    animationData={loadingLottieJson}
                    play
                    style={{width: lottieWidth, align:"center", }}
                />
                    </Grid>
                    <Grid item xs={12} lg={4}> </Grid>
                </Grid>
            );
        }
    return (
        <Box sx={{flexGrow: 1}} p={1}>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={4} className={styles.homepageSecondBlock} ml={2}>
                    <h2>All crypto events on 1 page.</h2>
                    <small>
                        - Crypto events
                        <br></br>
                        - Token unlocks
                    </small>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={{width: lottieWidth, align:"center", margin:"0 0 0 100px" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={12}>
                    <Grid item xs={12} lg={12} className={styles.homepageSecondBlock}>
                    <h2>Token Unlocks</h2>
                    </Grid>
                    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={2}>
                        Name
                    </Grid>
                    <Grid item xs={12} lg={1}>
                        Market Cap
                    </Grid>
                    <Grid item xs={12} lg={1}>
                        Max Supply
                    </Grid>
                    <Grid item xs={12} lg={1}>
                        Total Locked
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        Unlocks per day
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        Next Event
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {this.state.apiData['tokenUnlocks'].map((item) => {
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                return (
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} lg={2}>
                            {item.name}
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            {item.market_cap}
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            {item.max_supply}
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            {item.total_locked}
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            {item.unlocks_per_day}
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Grid item xs={12} lg={6}>
                            <Countdown date={Date.now() + new Date(item.event_datetime).getTime() - Date.now()} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    {new Date(item.event_datetime).toLocaleDateString("en-US", options)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                );
            })}

            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={12}>
                    <Grid item xs={12} lg={12} className={styles.homepageSecondBlock}>
                        <h2>Crypto Events</h2>
                    </Grid>
                </Grid>
            </Grid>

            {this.state.apiData['calendar']['events'].map((item) => {
                let importantEvent;
                let sourceReliable='';
                if (item['important'] === 'true') {
                    importantEvent = <Tooltip title="Important Event"><ElectricBolt /></Tooltip>;
                }
                if (item['source_reliable'] === 'true') {
                    sourceReliable = <Tooltip title="Approved Source"><CheckCircle /></Tooltip>;
                }
                return (
                    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Grid item xs={12} lg={1}>

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <span className="font-weight-bold">{item['coin_data']['name']} </span>
                                    <span className="font-weight-light">({item['coin_data']['symbol']})</span>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    {item['caption']}
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    Event start: {item['date_start']}
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    {importantEvent}
                                    {sourceReliable}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}

        </Box>
    );
    }
}
export default Events;