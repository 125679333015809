import Lottie from 'react-lottie-player';
import lottieJson from './lottie/90049-content-marketing.json';
import styles from "./css/dark-theme.module.css";
import React, {Component} from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import loadingLottieJson from "./lottie/animation_lkfxk9f1";

const authKey = process.env.REACT_APP_AUTH_KEY;
const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
const nodeEnv = process.env.REACT_APP_NODE_ENV;
let serverUrl = 'Check env';
if (nodeEnv === 'dev') {
    serverUrl = localServerUrl;
}
if (nodeEnv === 'prod') {
    serverUrl = prodServerUrl;
}

class Blog extends Component {

    state = {
        isLoaded: false,
        apiData: [],
    };
    async componentDidMount() {
        const packets = {
            authToken: authKey,
        };
        try {
            await axios.post(serverUrl + '/apiv2/g/lastblogs', packets, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({ apiData: response.data.data, isLoaded: true,});
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const lottieWidth = window.innerWidth * 30 / 100;

        if (!this.state.isLoaded) {
            return (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4}> </Grid>
                    <Grid item xs={12} lg={4}>
                        <Lottie
                            loop
                            animationData={loadingLottieJson}
                            play
                            style={{width: lottieWidth, align:"center", }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}> </Grid>
                </Grid>
            );
        }
    return (
        <Box sx={{flexGrow: 1}} p={1}>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={4} className={styles.homepageSecondBlock} ml={2}>
                    <h2>All crypto news on 1 page.</h2>
                    <small>
                        - Crypto news
                        <br></br>
                        - Educational content
                        <br></br>
                        - Analytical reports
                    </small>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={{width: lottieWidth, align:"center", margin:"0 0 0 100px" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                {this.state.apiData['lastArticles'].data.map((item) => {
                    return (
                        <Grid item xs={12} lg={4}>
                            <Card sx={{  }}>
                                <CardActionArea href={'blog/'+item.link}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={item.image}
                                        alt={item.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.short_description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
    }
}
export default Blog;