import Lottie from 'react-lottie-player';
import businessChart from './lottie/business-chart.json';
import updatesAnimation from './lottie/animation_lk5nq9sm.json';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styles from "./css/dark-theme.module.css";
import React from "react";
import { useRef } from 'react';

function Home() {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const lottieWidth = windowSize.current[0]*30/100;

    return (
        <Box sx={{flexGrow: 1}} p={5}>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12} lg={4} className={styles.homepageFirstBlock+" "+styles.boxShadow} ml={2}>
                    <h2>The Next Generation <br></br>Analytics Tool</h2>
                    <small>Access the finest and most reliable data on the crypto market.
                    <br></br>Our data is not only tailored for professional traders but also beginners.</small>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Lottie
                        loop
                        animationData={businessChart}
                        play
                        style={{width: lottieWidth, align:"center", margin:"0 0 0 100px" }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={12} className={styles.homepageSecondBlock}>
                    <h2>Who can use Coinscope?</h2>
                </Grid>
                <Grid item xs={12} lg={3} className={styles.homepageSecondBlock}>
                </Grid>
                <Grid item xs={12} lg={6} className={styles.homepageSecondBlock}>
                    <small>Our data is not only tailored for professional traders but also beginners. Whether you're a seasoned investor or just starting out, our platform provides valuable insights and information. Even if you prefer to hold your coins for the long term, you can discover fascinating data and trends within the market.</small>
                </Grid>
                <Grid item xs={12} lg={3} className={styles.homepageSecondBlock}>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={4} className={styles.homepageSecondBlock} ml={2}>
                    <h2>Latest Project Updates:</h2>
                    <small>
                        - Webview updated
                        <br></br>
                        - Added data about all coins
                        <br></br>
                        - Added bot, which checking gas price, every 5min
                        <br></br>
                        - Added a news sentiment checker that can analyze any tokens
                    </small>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Lottie
                        loop
                        animationData={updatesAnimation}
                        play
                        style={{width: lottieWidth, align:"center", margin:"0 0 0 100px" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
export default Home;