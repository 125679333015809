import React from 'react';

//components
import Navbar from '../components/Navbar';

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <Navbar/>
            {children}
        </React.Fragment>
    );
}

export default Layout;