import Lottie from 'react-lottie-player';
import lottieJson from './lottie/cryptocurrency-1702945185799.json';
import styles from "./css/dark-theme.module.css";
import React, {Component} from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import loadingLottieJson from "./lottie/animation_lkfxk9f1";

const authKey = process.env.REACT_APP_AUTH_KEY;
const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
const nodeEnv = process.env.REACT_APP_NODE_ENV;
let serverUrl = 'Check env';
if (nodeEnv === 'dev') {
    serverUrl = localServerUrl;
}
if (nodeEnv === 'prod') {
    serverUrl = prodServerUrl;
}

class Cryptocurrencies extends Component {

    state = {
        isLoaded: false,
        apiData: [],
    };
    async componentDidMount() {
        const packets = {
            authToken: authKey,
        };
        try {
            await axios.post(serverUrl + '/apiv2/g/topcoins', packets, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({ apiData: response.data.data, isLoaded: true,});
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const lottieWidth = window.innerWidth * 30 / 100;

        if (!this.state.isLoaded) {
            return (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4}> </Grid>
                    <Grid item xs={12} lg={4}>
                        <Lottie
                            loop
                            animationData={loadingLottieJson}
                            play
                            style={{width: lottieWidth, align:"center", }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}> </Grid>
                </Grid>
            );
        }
    return (
        <Box sx={{flexGrow: 1}} p={1}>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={4} className={styles.homepageSecondBlock} ml={2}>
                    <h2>All token/coin data on one page.</h2>
                    <small>
                        - Tokens TVL
                        <br></br>
                        - Tokens trade analysis
                        <br></br>
                        - Check for scam
                    </small>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={{width: lottieWidth, align:"center", margin:"0 0 0 100px" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                <Grid item xs={12} lg={12}>
                    <Stack
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                        direction="row" useFlexGap flexWrap="wrap"
                        justifyContent="center"
                        alignItems="center"
                    >
                {this.state.apiData['coinData'].data.map((item) => {
                    return (
                        <Button variant="outlined" className={styles.buttonCryptocurrenciesSymbol}>
                            <a href={'currencies/' + item.coin_symbol}>
                                <h2><img src={item.coin_logo} height={24} width={24}
                                         alt={item.coin_symbol}/> {item.coin_name}
                                    <small className={styles.coinSymbol}> [{item.coin_symbol}]</small>
                                </h2>
                            </a>
                        </Button>
                    );
                })}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
    }
}
export default Cryptocurrencies;