import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LogoW from '../images/logo_w.png';
import styles from "../css/dark-theme.module.css";


const Navbar = ({ children }) => {
    let homeMenuClass = styles.active_menu;
    let metricsMenuClass = styles.header_web_menu;
    let blogMenuClass = styles.header_web_menu;
    let eventsMenuClass = styles.header_web_menu;
    let currenciesMenuClass = styles.header_web_menu;
    let airdropsMenuClass = styles.header_web_menu;
    if(window.location.href.indexOf("metrics") > -1) {
        metricsMenuClass = styles.active_menu;
        homeMenuClass = styles.header_web_menu;
    }
    if(window.location.href.indexOf("blog") > -1) {
        blogMenuClass = styles.active_menu
        homeMenuClass = styles.header_web_menu;
    }
    if(window.location.href.indexOf("events") > -1) {
        eventsMenuClass = styles.active_menu;
        homeMenuClass = styles.header_web_menu;
    }
    if(window.location.href.indexOf("currencies") > -1) {
        currenciesMenuClass = styles.active_menu;
        homeMenuClass = styles.header_web_menu;
    }
    if(window.location.href.indexOf("airdrops") > -1) {
        airdropsMenuClass = styles.active_menu;
        homeMenuClass = styles.header_web_menu;
    }

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }} pb={4}>
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                    <Grid className={styles.header_logo_text} item xs={12} lg={4}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            >
                        <Grid className={styles.header_web_menu} item xs={2} lg={1}>
                            <a href={'/'}>
                                <img src={LogoW} width={'50'} className="back" alt="logo"/>
                            </a>
                        </Grid>
                        <Grid className={styles.header_web_menu} item xs={10} lg={5}>
                            <a href={'/'}>
                                <p>Coinscope</p>
                            </a>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={homeMenuClass} item xs={12} lg={1}>
                        <a href={'/'}>Home </a>
                    </Grid>
                    <Grid className={metricsMenuClass} item xs={12} lg={1}>
                        <a href={'/metrics'}>Metrics </a>
                    </Grid>
                    <Grid className={blogMenuClass} item xs={12} lg={1}>
                        <a href={'/blog'}>Blog </a>
                    </Grid>
                    <Grid className={eventsMenuClass} item xs={12} lg={1}>
                        <a href={'/events'}>Events </a>
                    </Grid>
                    <Grid className={airdropsMenuClass} item xs={12} lg={1}>
                        <a href={'/airdrops'}>Airdrops </a>
                    </Grid>
                    <Grid className={currenciesMenuClass} item xs={12} lg={3}>
                        <a href={'/currencies'}>Cryptocurrencies </a>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default Navbar;