import React, { useEffect, useRef, memo } from 'react';

function EconomicCalendarScriptTradingView() {
    const container = useRef();

    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
            "width": "100%",
            "height": "500",
            "colorTheme": "dark",
            "isTransparent": false,
            "locale": "en",
            "importanceFilter": "0,1",
            "currencyFilter": "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP"
        }`;
            container.current.appendChild(script);
        },
        []
    );

    return (
        <div className="tradingview-widget-container" id="calendar" ref={container}>
        </div>

    );
}

export default memo(EconomicCalendarScriptTradingView);